/** @jsx jsx */
import { jsx } from "theme-ui"

import logoDictionary from "./logo-dictionary"

const compareButtonStyles = {
  display: `flex`,
  flexDirection: `column`,
  fontFamily: `heading`,
  alignItems: `center`,
  justifyContent: `center`,
  borderWidth: 1,
  borderStyle: `solid`,
  borderRadius: 1,
  p: 3,
  ":hover": {
    borderColor: `purple.60`,
    cursor: `pointer`,
  },
  ":focus": {
    outline: 0,
    boxShadow: t => `0 0 0 ${t.space[2]} ${t.colors.purple[60]}`,
  },
}

const CompareButton = ({ children, optionKey, selected, setSelected }) => (
  <button
    sx={{
      ...compareButtonStyles,
      backgroundColor: selected ? `purple.50` : `primaryBackground`,
      borderColor: selected ? `purple.60` : `grey.20`,
      color: selected ? `white` : `text.secondary`,
    }}
    onClick={() => setSelected({ [optionKey]: !selected })}
  >
    <img
      sx={{
        height: t => t.space[7],
        mb: 0,
      }}
      src={logoDictionary[optionKey]}
      alt={optionKey}
    />
    {/* TODO: fix alt */}
    {children}
  </button>
)

export default CompareButton
