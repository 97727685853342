/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { LinkButton } from "gatsby-interface"

import Container from "../../components/container"
import Header from "../../components/header"
import Layout from "../../layouts"
import Title from "../../components/hero/title"
import LegendTable from "../../components/features/legend-table"
import EvaluationTable from "../../components/features/evaluation-table"
import CompareButton from "../../components/features/compare-button"
import useComparisonState from "../../components/hooks/use-comparison-state"
import featureComparisonOptions from "../../../data/feature-comparison-options.json"
import { getFeaturesData } from "../../utils/get-csv-features-data"
import PageWithSidebar from "../../components/page-with-sidebar"
import Breadcrumb from "../../components/docs/breadcrumb"
import PageMetadata from "../../components/page-metadata"

const CMSFeaturesPage = ({ data: { allGatsbyCmsSpecsCsv }, location }) => {
  const [selected, setSelected, comparators, hasSelected] = useComparisonState({
    wordpress: false,
    drupal: false,
  })

  const { sections, sectionHeaders } = getFeaturesData(
    allGatsbyCmsSpecsCsv.nodes
  )

  return (
    <Layout pathname={location.pathname}>
      <PageMetadata title="Gatsby vs Traditional CMS" />
      <Header isFullWidth isFixed />
      <PageWithSidebar location={location}>
        <main
          id={`reach-skip-nav`}
          sx={t => ({
            pb: 0,
            [t.mediaQueries.desktop]: {
              pt: 10,
            },
          })}
        >
          <Container customStyles={{ position: `static` }}>
            <Breadcrumb location={location} />
            <Title>CMS</Title>
            <p>Compare popular CMS options on this page.</p>

            <h3>Comparison</h3>
            <p>
              To see a filtered view of Gatsby compared with specific CMS
              technologies, choose the technologies to compare and then press
              Compare:
            </p>
            <div sx={{ pb: 11 }}>
              <div
                sx={{
                  display: `grid`,
                  gridTemplateColumns: `repeat(auto-fit, minmax(75px, 1fr))`,
                  gridAutoRows: `1fr`,
                  gridGap: 3,
                  pb: 5,
                }}
              >
                {featureComparisonOptions.cms.map(
                  ({ key: optionKey, display }) => (
                    <CompareButton
                      key={optionKey}
                      optionKey={optionKey}
                      selected={selected[optionKey]}
                      setSelected={setSelected}
                    >
                      {display}
                    </CompareButton>
                  )
                )}
              </div>
              <LinkButton
                to={
                  hasSelected
                    ? `/features/cms/gatsby-vs-${comparators.join(`-vs-`)}`
                    : location.pathname
                }
                size="M"
              >
                Compare with Gatsby
              </LinkButton>
            </div>
            <LegendTable />
            <EvaluationTable
              options={featureComparisonOptions.cms}
              sections={sections}
              sectionHeaders={sectionHeaders}
            />
          </Container>
        </main>
      </PageWithSidebar>
    </Layout>
  )
}

export default CMSFeaturesPage

export const pageQuery = graphql`
  query CMSFeaturePageQuery {
    allGatsbyCmsSpecsCsv {
      nodes {
        Category
        Subcategory
        Feature
        Gatsby
        WordPress
        Drupal
        Description
      }
    }
  }
`
